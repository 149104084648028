import { LineItem, Order } from '@commercetools/platform-sdk';
import { cents, dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import createDebug from 'debug';
import mapValues from 'lodash/mapValues';
import sum from 'lodash/sum';
import type { SetRequired } from 'type-fest';

import { getIsFirstOrder } from '@/api/checkout/payment';
import type { ExpressLocation } from '@/composables/useExpressCheckout';
import { useLocalStorage } from '@/composables/useLocalStorage';
import type { PaymentItem } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import { NutsAddress } from '@/utils/address';
import { sendPurchaseToFacebook } from '@/utils/analytics/facebook';
import { sendOrderPlacedEvent } from '@/utils/analytics/orderPlacedEvent';
import { isPackingSlipMessageCustomLineItem } from '@/utils/cart';
import { sha256 } from '@/utils/crypto';
import { Money } from '@/utils/money';
import { getUnitName } from '@/utils/product';
import { Extole } from '@/utils/referral';
import { reportError } from '@/utils/reportError';
import { getSsrState } from '@/utils/ssrStorage';

const debug = createDebug('nuts:analytics');

interface AnalyticsProduct {
  readonly autoDeliveryInterval?: number;
  readonly id: string;
  readonly itemExternalId?: string;
  readonly name: string;
  readonly price: number;
  readonly primaryMerchandisingCategory?: string;
  readonly quantity: number;
}

export interface DataPromo {
  readonly creative: string;
  readonly name: string;
  readonly type: string;
}
export interface GAEvent {
  action: string;
  category: string;
  ecommerce?: object;
  label?: string | null;
}

export interface GTMRecord {
  ecommerce?: object;
  event: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string | null;
  [key: string]: any;
}

export interface ListMetadata {
  category?: {
    indexName: string;
    objectID: string;
    position: number;
    searchQueryID?: string;
  };
  indexName?: string;
  list: string;
  position: number;
  searchQueryID?: string;
}

export interface NutsPurchaseItemParams extends Gtag.PurchaseItemParams {
  autodelivery_interval?: number;
  cost?: number;
  item_variant_name?: string;
  marked_as_gift?: boolean;
  price_before_discount: number;
  reporting_category?: string;
  weight: number;
}

interface NutsPurchaseParams
  extends SetRequired<Gtag.PurchaseParams, 'currency' | 'items' | 'shipping' | 'tax' | 'value'> {
  credit?: number;
  discount?: number;
  express_location?: ExpressLocation;
  gift_message: boolean;
  hrp?: number;
  items: NutsPurchaseItemParams[];
  new_customer?: boolean;
  placed_by: 'Customer' | 'Employee' | 'System';
  subtotal: number;
  surcharge?: number;
  shipping_carrier?: string;
  shipping_carrier_code?: string;
}

const parseListMetadata = (listMetadata: string): ListMetadata | undefined => {
  try {
    return JSON.parse(listMetadata);
  } catch {
    return undefined;
  }
};

export const getListMetadata = () => {
  const data = useLocalStorage().get<ListMetadata>('listMetadata');
  return data ?? undefined;
};

export const setListMetadata = (data: ListMetadata) => {
  useLocalStorage().set('listMetadata', data);
};

export const flattenCategoryMetadata = (
  categoryMetadata: ListMetadata['category'],
  fieldPrefix: string,
) => {
  const flattened: { [key: string]: any } = {};
  if (categoryMetadata) {
    Object.entries(categoryMetadata).forEach(([key, value]) => {
      flattened[`${fieldPrefix}_${key}`] = value;
    });
  }
  return flattened;
};

const formatListMetadata = (
  listMetadata: ListMetadata | undefined,
):
  | {
      index: number;
      indexName: string | null;
      item_list_name: string;
      searchQueryID?: string;
    }
  | undefined => {
  if (!listMetadata) return undefined;
  const categoryMetadata = flattenCategoryMetadata(listMetadata.category, 'listMetadata_category');
  return {
    index: listMetadata.position,
    indexName: listMetadata.indexName ?? null,
    item_list_name: listMetadata.list,
    searchQueryID: listMetadata.searchQueryID,
    ...categoryMetadata,
  };
};

export const formatPurchaseItem = (lineItem: NutsLineItem): NutsPurchaseItemParams => ({
  autodelivery_interval: lineItem.custom?.fields.autoDeliveryInterval,
  cost: dollars(lineItem.variant.pieceCost),
  coupon: lineItem.totalSavings?.description?.en,
  discount: dollars(lineItem.totalSavings?.value || from(0)),
  item_id: lineItem.productKey,
  item_name: lineItem.name.en,
  item_variant: lineItem.variant.sku,
  item_variant_name: getUnitName(lineItem.variant.variantName) ?? undefined,
  marked_as_gift: lineItem.custom?.fields.markedAsGift,
  price: dollars(lineItem.piecePrice),
  price_before_discount: dollars(lineItem.variant.prices?.find((p) => !p.channel)?.value),
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find(({ name }) => name === 'reportingCategory')
    ?.value.key,
  weight: lineItem.variant.weight,
  ...(lineItem.custom?.fields.listMetadata
    ? formatListMetadata(parseListMetadata(lineItem.custom.fields.listMetadata)) ?? {}
    : {}),
});

export function formatPurchase({
  expressLocation,
  newCustomer,
  order: { discountCodes, lineItems, orderNumber, taxedPrice },
  paymentItems,
  placedBy,
  shipmentPickupCarrier,
  shipmentPickupCarrierCode,
}: {
  expressLocation?: ExpressLocation;
  newCustomer?: boolean;
  order: Order;
  paymentItems: PaymentItem[];
  placedBy: 'Employee' | 'Customer' | 'System';
  shipmentPickupCarrier?: string;
  shipmentPickupCarrierCode?: string;
}): NutsPurchaseParams {
  // pivot payment items by label and convert to dollars
  const pi = Object.fromEntries(
    paymentItems.map((item) => [item.label, dollars(item.amount)]),
  ) as Record<PaymentItem['label'], number | undefined>;

  const formattedPurchase: NutsPurchaseParams = {
    coupon: discountCodes?.[0]?.discountCode.obj?.name?.en,
    credit: pi['Store Credit'],
    currency: 'USD',
    discount: sum([pi.Discount, pi.Adjustment]), // ask David if he cares about omitting vs $0
    express_location: expressLocation,
    gift_message: false,
    hrp: pi['Heat-Resistant Packaging'],
    items: lineItems.map((li) => formatPurchaseItem(NutsLineItem.fromCt(li))),
    new_customer: newCustomer,
    placed_by: placedBy,
    shipping: pi.Shipping || 0,
    subtotal: pi.Subtotal || 0,
    surcharge: pi['Temporary Surcharge'],
    tax: sum([pi.Tax, pi.Duties, pi['GST/HST']]) || 0,
    transaction_id: orderNumber!,
    value: dollars(taxedPrice!.totalNet),
  };

  if (shipmentPickupCarrier) {
    formattedPurchase.shipping_carrier = shipmentPickupCarrier;
  }
  if (shipmentPickupCarrierCode) {
    formattedPurchase.shipping_carrier_code = shipmentPickupCarrierCode;
  }

  return formattedPurchase;
}

export const formatAnalyticsProductsForUA = (lineItems: LineItem[]): AnalyticsProduct[] =>
  lineItems.map((lineItem) => {
    const pieceCost = lineItem.variant.attributes?.find(
      (a: { name: string }) => a.name === 'pieceCost',
    )?.value;
    return {
      autoDeliveryInterval: lineItem.custom?.fields.autoDeliveryInterval,
      id: `0${lineItem.variant.sku}`,
      itemExternalId: lineItem.productKey,
      name: lineItem.name.en,
      pieceCost: pieceCost ? dollars(pieceCost) : undefined,
      price: dollars(lineItem.price.value),
      primaryMerchandisingCategory: lineItem.variant.attributes?.find(
        (a: { name: string }) => a.name === 'merchandisingCategory',
      )?.value.label,
      quantity: lineItem.quantity,
    };
  });

const formatPurchaseForUA = (context: {
  newCustomer?: boolean;
  order: Order;
  paymentItems: PaymentItem[];
  placedByAdmin?: boolean;
}): {
  containsAutoDelivery: boolean;
  containsGiftMessage: boolean;
  containsGreetingCard: boolean;
  containsMarkedAsGift: boolean;
  coupon: string;
  discounts: number;
  estimatedProfit?: number; // TODO
  id: string;
  newCustomer?: boolean;
  placedByAdmin: boolean;
  postalCode?: string;
  revenue: number;
  sameBillingAddress: boolean;
  shipping: number;
  subtotalMinusCoupons: number;
  tax: number;
} => {
  const { order, paymentItems } = context;

  const paymentItemsByLabel = Object.fromEntries(
    paymentItems.map(({ label, amount }) => [label, amount]),
  ) as Record<PaymentItem['label'], PaymentItem['amount'] | undefined>;

  let coupon = '';
  const { discountCodes } = order;
  if (discountCodes) {
    coupon = discountCodes[0]?.discountCode.obj?.name?.en ?? '';
  }

  let postalCode;
  if (order.billingAddress) {
    ({ postalCode } = NutsAddress.fromCt(order.billingAddress));
  }

  const subtotalMinusCoupons = dollars(
    Money.sum([paymentItemsByLabel.Subtotal, paymentItemsByLabel.Discount]),
  );

  return {
    containsAutoDelivery: order.lineItems.some((i) => !!i.custom?.fields.autoDeliveryInterval),
    containsGiftMessage: order.customLineItems.some(isPackingSlipMessageCustomLineItem),
    containsGreetingCard: order.lineItems.some(
      (i) => !!i.custom && 'greetingCardMessage' in i.custom.fields,
    ),
    containsMarkedAsGift: order.lineItems.some((i) => i.custom?.fields.markedAsGift),
    coupon,
    discounts: dollars(paymentItemsByLabel.Discount) || 0,
    id: order.orderNumber!,
    newCustomer: context.newCustomer,
    placedByAdmin: context.placedByAdmin ?? false,
    postalCode,
    revenue: dollars(order.taxedPrice?.totalGross),
    sameBillingAddress: order.billingAddress?.streetName === order.shippingAddress?.streetName,
    shipping: dollars(paymentItemsByLabel.Shipping) || 0,
    subtotalMinusCoupons,
    tax: dollars(paymentItemsByLabel.Tax) || 0,
  };
};

type PaymentOption = 'ApplePay' | 'CreditAccount' | 'Offline' | 'PayPal' | 'CreditCard' | 'ShopPay';

export function derivePaymentMethod({
  amountToCharge,
  paymentOption,
  cardType,
  giftCertificateAmount,
  storeCreditAmount,
}: {
  amountToCharge: Money;
  paymentOption: PaymentOption;
  cardType?: string;
  giftCertificateAmount?: Money;
  storeCreditAmount?: Money;
}): string | undefined {
  // fully covered by gift certificate or store credit
  if (cents(amountToCharge) === 0) {
    if (giftCertificateAmount) return 'Gift Certificate';
    if (storeCreditAmount) return 'Store Credit';

    // in case a discount brings it to 0
    return undefined;
  }

  if (paymentOption === 'CreditCard') return cardType;

  return paymentOption.replace('ApplePay', 'Apple Pay');
}

/**
 * Call window.gtag() safely
 *
 * Checks if window and window.gtag are defined first. If called during SSR,
 * attempts to store for later rendering into index.html where it'll get
 * reinjected into window.gtag().
 */
export const gtag = <Gtag.Gtag>((...args: Parameters<Gtag.Gtag>) => {
  if (import.meta.env.SSR) {
    getSsrState().dataLayer?.push(args);
  } else if (typeof window !== 'undefined' && window.gtag) {
    window.gtag(...args);
  }
});

export async function hashEmailAddress(rawEmail: string) {
  const email = rawEmail.trim().toLowerCase();
  const hashedEmail = await sha256(email);
  return hashedEmail;
}

export async function hashPhoneNumber(rawPhone: string) {
  // drop all non-digits
  let phone = rawPhone.replace(/\D/g, '');
  // add country code if obviously missing
  if (phone.length === 10) {
    phone = `1${phone}`;
  }
  return sha256(phone);
}

export const pushToDataLayer = (record: GTMRecord) => {
  if (typeof window === 'undefined') {
    debug('window is undefined');
    return;
  }

  if (!window.dataLayer) {
    debug('window.dataLayer is undefined');
    return;
  }

  const { event, ...fields } = record;
  const resetFields = mapValues(fields, (_) => null);

  window.dataLayer.push(resetFields);
  window.dataLayer.push(record);
  window.dataLayer.push(resetFields);
};

const pushEvent = (event: string, record: GAEvent) => {
  const { action, category, label = null, ...other } = record;
  pushToDataLayer({
    event,
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    ...other,
  });
};

export const dyEvent = (event: GAEvent) => {
  pushEvent('dyEvent', event);
};

export const gaEvent = (event: GAEvent) => {
  pushEvent('gaEvent', event);
};

export const CartEvents = {
  remove: (removedLineItem: AnalyticsProduct) => {
    pushToDataLayer({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [removedLineItem],
        },
      },
    });
  },
};

export function event(
  eventName: 'purchase',
  eventParams?: NutsPurchaseParams | Gtag.ControlParams | Gtag.CustomParams,
): void;
export function event(
  eventName: Gtag.EventNames | string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) {
  gtag('event', eventName, eventParams);
}

export const CheckoutEvents = {
  event: ({
    action,
    category = 'Checkout Event',
    label,
  }: {
    action: string;
    category?: string;
    label: string;
  }) => {
    pushEvent('checkoutEvent', { category, action, label });
  },
  option: (stepNumber: number, option: string) => {
    pushEvent('checkoutOption', {
      category: 'Ecommerce',
      action: 'Checkout Option',
      label: null,
      ecommerce: {
        checkout_option: {
          actionField: { step: stepNumber, option },
        },
      },
    });
  },
  async purchase({
    currentCardType,
    expressLocation,
    isAdmin,
    order,
    paymentItems,
    paymentOption,
    receiptToken,
    shipmentPickupCarrier,
    shipmentPickupCarrierCode,
  }: {
    currentCardType?: string;
    expressLocation?: ExpressLocation;
    isAdmin?: boolean;
    order: Order;
    paymentItems: PaymentItem[];
    paymentOption: PaymentOption;
    receiptToken: string;
    shipmentPickupCarrier?: string;
    shipmentPickupCarrierCode?: string;
  }) {
    window.nutsServerLog?.('purchase-begin');

    const paymentItemsByLabel = Object.fromEntries(
      paymentItems.map(({ label, amount }) => [label, amount]),
    ) as Record<PaymentItem['label'], PaymentItem['amount'] | undefined>;

    // Extole
    Extole.purchase(order, paymentItemsByLabel.Subtotal);
    window.nutsServerLog?.('purchase-sent-extole');

    // Facebook (via our Express forwarder)
    sendPurchaseToFacebook(order, paymentItems);
    window.nutsServerLog?.('purchase-sent-fb');

    // Check if this is the customer's first order (currently only used for GA4 and GTM)
    let newCustomer: boolean | undefined;
    try {
      newCustomer = await getIsFirstOrder(receiptToken);
    } catch (e) {
      reportError(e);
    }

    // RudderStack
    const paymentMethod = derivePaymentMethod({
      amountToCharge: Money.sumBy(paymentItems, (item) => item.amount),
      paymentOption,
      cardType: currentCardType,
      giftCertificateAmount: paymentItemsByLabel['Gift Certificate'],
      storeCreditAmount: paymentItemsByLabel['Store Credit'],
    });
    sendOrderPlacedEvent(order, paymentItems, paymentMethod, newCustomer);
    window.nutsServerLog?.('purchase-sent-rs');

    // GA4
    event(
      'purchase',
      formatPurchase({
        expressLocation,
        newCustomer,
        order,
        paymentItems,
        placedBy: isAdmin ? 'Employee' : 'Customer',
        shipmentPickupCarrier,
        shipmentPickupCarrierCode,
      }),
    );
    window.nutsServerLog?.('purchase-sent-ga4');

    // GTM (where we expect UA format)
    const actionField = formatPurchaseForUA({
      newCustomer,
      order,
      paymentItems,
      placedByAdmin: isAdmin,
    });
    const products = formatAnalyticsProductsForUA(order.lineItems);
    pushEvent('orderPlaced', {
      action: 'Purchase',
      category: 'Ecommerce',
      label: null,
      ecommerce: {
        purchase: { actionField, products },
      },
    });
    window.nutsServerLog?.('purchase-sent-ua');
  },
  validation: ({
    error,
    field,
    stepNumber,
  }: {
    error: string;
    field: string;
    stepNumber: number;
  }) => {
    pushEvent('checkoutValidation', {
      action: `Step ${stepNumber}: ${field}`,
      category: 'Checkout Validation Error',
      label: `Error: ${error}`,
    });
  },
  validInput: ({ stepNumber, field }: { stepNumber: number; field: string }) => {
    pushEvent('checkoutValidation', {
      action: `Step ${stepNumber}: ${field}`,
      category: 'Checkout Validation Success',
      label: 'Success',
    });
  },
};

const sendEvent = (evt: string, location: string, body: any) => {
  let activity = {
    event: evt,
    ...body,
  };
  if (location) {
    activity = { ...activity, location };
  }
  window.dataLayer.push(activity);
};

export default {
  sendEvent,
};
