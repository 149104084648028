import type { ApiCallback, ApiObject, ApiOptions } from '@rudderstack/analytics-js';
import { RouteLocationNormalized, Router } from 'vue-router';

import { deriveDyContext } from '@/router/createRouter';
import { setRudderTyperOptions } from '@/rudder-typer';
import { gtag } from '@/utils/analytics';

/**
 * Additional fields we attach to each track event's context.page
 */
interface NutsTrackPageContext {
  category?: string;
  referrer?: string;
}

let trackPageContext: NutsTrackPageContext | undefined;

export function getTrackPageContext() {
  return trackPageContext;
}

export function installPageEvents(router: Router) {
  let firstRoute = true;

  function sendSpaStart(to: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_start', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }
  }

  function sendPageEvent(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_end', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }

    if (to.meta.gtagPageType) gtag('set', 'content_group', `${to.meta.gtagPageType}`);

    const pushChangeEvent = new CustomEvent<{
      from?: RouteLocationNormalized;
      to: RouteLocationNormalized;
    }>('onRoutingComplete', {
      detail: { to, from },
    });
    document.dispatchEvent(pushChangeEvent);

    if (to.path !== from?.path || (to.path === '/' && from?.path === '/')) {
      const category = to.meta.gtagPageType;
      const payload: { path: string; url: string; referrer?: string } = {
        path: to.path,
        url: `${window.location.origin}${to.fullPath}`,
      };
      trackPageContext = { category };

      // manually set for all spa navigation, otherwise let RS take care of it
      if (!firstRoute) {
        payload.referrer = `${window.location.origin}${from.fullPath}`;
        trackPageContext.referrer = payload.referrer;
      }

      window.rudderanalytics?.page(category ?? '', '', payload);

      firstRoute = false;
    }
  }

  /**
   * Copy `trackPageContext` into `context.page` but let any existing values win
   */
  function withPageContext(options: ApiOptions): ApiOptions {
    const context = (options.context as ApiObject) ?? {};
    const page = (context.page as ApiObject) ?? {};

    const result = {
      ...options,
      context: {
        ...context,
        page: { ...trackPageContext, ...page },
      },
    };
    return result;
  }

  router.beforeResolve(sendSpaStart);
  router.afterEach(sendPageEvent);

  setRudderTyperOptions({
    analytics: {
      // since this will only be used by RudderTyper and it _always_ passes 3+
      // arguments, we don't need to handle all the combinations of optional
      // arguments.
      track(
        event: string,
        properties: ApiObject,
        options: ApiOptions,
        callback?: ApiCallback,
      ): void {
        window.rudderanalytics?.track(event, properties, withPageContext(options), callback);
      },
    },
  });
}
