import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import type { ApiObject, IdentifyTraits } from '@rudderstack/analytics-js';
import type { IsEqual } from 'type-fest/source/internal';

import { NutsLineItem } from '@/lib/cart/lineItem';
import {
  CartViewedProductsItem,
  CheckoutStartedProductsItem,
  OrderPlacedProductsItem,
  productsSearched,
} from '@/rudder-typer';
import { getTrackPageContext } from '@/utils/analytics/pageEvent';
import { isDefined } from '@/utils/isDefined';
import { getUnitName } from '@/utils/product';

interface IdentifyEventFromServer {
  context?: Record<string, unknown>;
  traits: IdentifyTraits;
  type: 'identify';
  userId?: string;
}

interface TrackEventFromServer {
  context?: Record<string, unknown>;
  event: string;
  properties: ApiObject;
  type: 'track';
}

export type RudderstackEventFromServer = IdentifyEventFromServer | TrackEventFromServer;

export interface GoogleEventObjItem {
  coupon?: string;
  index?: number;
  item_id: string;
  item_name?: string;
  item_variant?: string;
  price: number;
  quantity?: number;
}

export interface GoogleEventObj {
  items: GoogleEventObjItem[];
}

export type StandardProductsItem = CartViewedProductsItem;

// assert that various cart/checkout-related RS *ProductsItem types are identical
type StaticAssert<T extends true> = T;
type _check = StaticAssert<IsEqual<StandardProductsItem, CartViewedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, CheckoutStartedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, OrderPlacedProductsItem>>;

export const formatStandardProductsItem = (lineItem: NutsLineItem): StandardProductsItem => ({
  name: lineItem.name?.en,
  price: dollars(lineItem.piecePrice),
  product_id: lineItem.productKey,
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find((attr) => attr.name === 'reportingCategory')
    ?.value.key,
  sku: lineItem.variant.sku,
  variant: getUnitName(lineItem.variant.variantName) ?? undefined,
});

export const sendProductsSearchedEvent = (query: string): void => {
  if (query) productsSearched({ query });
};

export function sendRudderstackEvents(events: RudderstackEventFromServer[]) {
  events.forEach((payload) => {
    if (payload.type === 'track') {
      const pageContext = getTrackPageContext();
      window.rudderanalytics?.track(payload.event, payload.properties, {
        page: { ...pageContext },
      });
    } else if (payload.type === 'identify') {
      if (isDefined(payload.userId)) {
        window.rudderanalytics?.identify(payload.userId, payload.traits);
      } else {
        window.rudderanalytics?.identify(payload.traits);
      }
    }
  });
}
