// eslint-disable-next-line max-classes-per-file
import 'isomorphic-fetch';

import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk';
import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth';
import { ClientBuilder } from '@commercetools/sdk-client-v2';
import { webstore as autoDeliveryWebstore } from '@nuts/auto-delivery-sdk';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import createDebug from 'debug';
import https from 'https';
import type { JsonObject } from 'type-fest';

import { assignRuntimeConfig, bundledConfig, getConfigEntry, getCtConfig } from '@/api/config';
import { useNotifications } from '@/stores/notifications';
import addDebugLogging from '@/utils/addDebugLogging';
import { gtag } from '@/utils/analytics';
import { RudderstackEventFromServer, sendRudderstackEvents } from '@/utils/analytics/rudderstack';
import { getSsrState } from '@/utils/ssrStorage';

interface ApiAxiosInstance extends AxiosInstance {
  setSharedTokenCache: (cache: { tokenInfo?: any }) => void;
}

interface WebstoreAxiosInstance extends AxiosInstance {
  sessionSpecific: AxiosInstance;
  useSsrConfig: () => void;
}

export interface NutsJsonMessage {
  field?: string;
  key?: string;
  message: string;
}

export interface NutsJson<T = any> {
  data: T;
  dataLayer?: any[];
  // eslint-disable-next-line camelcase
  redirect_to?: {
    url: string;
    target?: string;
  };
  rudderstackEvents?: RudderstackEventFromServer[];
  errors?: NutsJsonMessage[];
  notices?: NutsJsonMessage[];
  warnings?: NutsJsonMessage[];
}

export class KeyedError extends Error {
  constructor(message: string, public key: string) {
    super(message);
  }
}

export class NutsJsonError extends Error {
  constructor(message: string, public errors: NutsJsonMessage[]) {
    super(message);
    this.errors = errors;
  }
}

const initCtClient = () => {
  const auth = getConfigEntry('auth');
  const authHost = 'https://auth.us-central1.gcp.commercetools.com';
  const apiHost = 'https://api.us-central1.gcp.commercetools.com';
  const { projectKey } = auth;
  const authMiddlewareOptions = {
    host: authHost,
    projectKey,
    credentials: {
      clientId: auth.credentials.clientId,
      clientSecret: auth.credentials.clientSecret,
    },
    scopes: auth.scopes,
    fetch,
  };

  const httpMiddlewareOptions = {
    host: apiHost,
    fetch,
    enableRetry: true,
    retryConfig: {
      maxRetries: 3,
    },
  };
  const client = new ClientBuilder()
    .withProjectKey(projectKey)
    .withClientCredentialsFlow(authMiddlewareOptions)
    .withHttpMiddleware(httpMiddlewareOptions)
    .withUserAgentMiddleware()
    .build();
  return createApiBuilderFromCtpClient(client).withProjectKey({ projectKey });
};

// eslint-disable-next-line import/no-mutable-exports
export let ctApi = initCtClient();

const debug = createDebug('nuts:api');

let sharedTokenCache: { tokenInfo?: any } = {};

const createTokenProvider = () => {
  const ctConfig = getCtConfig();
  return new TokenProvider({
    sdkAuth: new SdkAuth(ctConfig),
    fetchTokenInfo: (sdkAuth: any) => sdkAuth.clientCredentialsFlow(),
    onTokenInfoRefreshed: (tokenInfo: any) => {
      sharedTokenCache.tokenInfo = tokenInfo;
    },
  });
};

// eslint-disable-next-line import/no-mutable-exports
let tokenProvider = createTokenProvider();

const getCtBaseUrl = () => {
  const apiConfig = getConfigEntry('api');
  const ctConfig = getCtConfig();
  return `${apiConfig.host}/${ctConfig.projectKey}`;
};

const api: ApiAxiosInstance = addDebugLogging(
  debug,
  axios.create({
    baseURL: getCtBaseUrl(),
  }),
);

api.interceptors.request.use(async (options) => {
  const start = Date.now();
  const tokenInfo = await tokenProvider.getTokenInfo();
  debug('got a token in %dms', Date.now() - start);
  // eslint-disable-next-line no-param-reassign
  options.headers.Authorization = `${tokenInfo.token_type} ${tokenInfo.access_token}`;
  return options;
});

api.setSharedTokenCache = (cache) => {
  sharedTokenCache = cache;
  if (cache.tokenInfo) {
    debug('received cached token');
    tokenProvider.setTokenInfo(cache.tokenInfo);
  }
};

let clientVersion: string | undefined;
if (typeof document !== 'undefined') {
  clientVersion =
    document
      .querySelector('script[src^="/assets/index"]')
      ?.getAttribute('src')
      ?.match(/index-(\w+)\.js/)?.[1] ?? 'unknown';
}
const httpsAgent =
  'Agent' in https &&
  new https.Agent({
    rejectUnauthorized: bundledConfig.webstore.secure,
  });
const createBaseWebstoreConfig = () => {
  const { host = '' } = getConfigEntry('webstore');
  return {
    baseURL: host,
    headers: {
      Accept: 'application/json',
      ...(clientVersion && { 'Webfront-Client-Version': clientVersion }),
    },
    httpsAgent,
  };
};

autoDeliveryWebstore.defaults = {
  agent: httpsAgent || undefined,
  baseURL: bundledConfig.webstore.host,
  headers: {},
};

const webstore: WebstoreAxiosInstance = addDebugLogging(
  debug.extend('webstore'),
  axios.create({
    ...createBaseWebstoreConfig(),
  }),
);

webstore.sessionSpecific = addDebugLogging(
  debug.extend('webstore:sessionSpecific'),
  axios.create({
    ...createBaseWebstoreConfig(),
  }),
);
if (typeof window === 'undefined') {
  webstore.sessionSpecific.interceptors.request.use((requestConfig) => {
    const store = getSsrState();
    if (store.cookie) {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.Cookie = store.cookie;
    }
    if (store.userAgent) {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers['User-Agent'] = store.userAgent;
    }
    return requestConfig;
  });
  autoDeliveryWebstore.defaults.fetch = (url, options) => {
    const store = getSsrState();
    const headers = new Headers(options?.headers);
    if (store.cookie) {
      headers.set('Cookie', store.cookie);
    }
    if (store.userAgent) {
      headers.set('User-Agent', store.userAgent);
    }
    return fetch(url, { ...options, headers });
  };
}

const setSsrConfig = () => {
  const { webstore: webstoreConfig } = getConfigEntry('ssr');
  const ssrHttpsAgent = new https.Agent({
    rejectUnauthorized: webstoreConfig.secure,
  });
  [webstore, webstore.sessionSpecific].forEach((instance) => {
    // eslint-disable-next-line no-param-reassign
    instance.defaults.baseURL = webstoreConfig.host;
    // eslint-disable-next-line no-param-reassign
    instance.defaults.httpsAgent = ssrHttpsAgent;
  });
  autoDeliveryWebstore.defaults.baseURL = webstoreConfig.host;
  autoDeliveryWebstore.defaults.agent = ssrHttpsAgent;
  debug('using SSR config');
  webstore.useSsrConfig = () => {
    debug('redundant useSsrConfig() call');
  };
};
webstore.useSsrConfig = setSsrConfig;

export const useRuntimeConfig = () => {
  ctApi = initCtClient();
  const { host = '' } = getConfigEntry('webstore');
  tokenProvider = createTokenProvider();
  api.defaults.baseURL = getCtBaseUrl();
  autoDeliveryWebstore.defaults.baseURL = host;
};

export const setAndUseRuntimeConfig = (configSchema: any) => {
  assignRuntimeConfig(configSchema);
  useRuntimeConfig();
};

export const setSsrRuntimeConfig = (configSchema: any) => {
  setAndUseRuntimeConfig(configSchema);
  setSsrConfig();
};

const assignLocation = (url: string) => {
  if (typeof window !== 'undefined') window.location.assign(url);
};
const pushDataLayer = (updates: (Parameters<typeof gtag> | JsonObject)[]) =>
  updates.forEach((update) => {
    // An array means it's for gtag rather than GTM
    if (Array.isArray(update)) {
      gtag(...update);
    } else if (import.meta.env.SSR) {
      getSsrState().dataLayer?.push(update);
    } else if (typeof window !== 'undefined') {
      window.dataLayer?.push(update);
    }
  });

export interface FromNutsJsonOptions<T = any> {
  onDataLayer?: (updates: object[]) => void;
  onMessages?:
    | ((messages: { errors?: any[]; notices?: any[]; warnings?: any[] }) => void)
    | 'default';
  onRedirect?: (url: string, target?: string, data?: T) => void | Promise<void>;
  throwError?: boolean;
}

export async function fromNutsJson<T>(
  response: NutsJson<T> | Promise<NutsJson<T>>,
  {
    onDataLayer = pushDataLayer,
    onMessages,
    onRedirect = assignLocation,
    throwError = false,
  }: FromNutsJsonOptions<T> = {},
): Promise<T> {
  const {
    data,
    dataLayer = [],
    errors = [],
    notices = [],
    rudderstackEvents = [],
    warnings = [],
    redirect_to: { url = undefined, target = undefined } = {},
  } = await response;

  if (onDataLayer && dataLayer.length) {
    onDataLayer(dataLayer);
  }

  if (rudderstackEvents.length && import.meta.env.SSR) {
    sendRudderstackEvents(rudderstackEvents);
  }

  let toThrow: Error | undefined;
  if (throwError && errors[0]?.message) {
    const [error] = errors;
    if (error.key) {
      toThrow = new KeyedError(error.message, error.key);
    } else {
      toThrow = new NutsJsonError(error.message, errors);
    }
    // If there are any other messages let them flow so they don't get lost;
    // after that we'll take care of throwing this one.
  }

  if (onMessages && (errors.length || notices.length || warnings.length)) {
    if (onMessages === 'default') {
      useNotifications().addNotifications({ errors, notices, warnings });
    } else {
      onMessages({ errors, notices, warnings });
    }
  }

  if (onRedirect && url) {
    await onRedirect(url, target, data);
  }

  if (toThrow) {
    throw toThrow;
  }

  return data;
}

export async function fromAxiosNutsJson<T>(
  response: AxiosResponse<NutsJson<T>> | Promise<AxiosResponse<NutsJson<T>>>,
  options: FromNutsJsonOptions<T>,
): Promise<T> {
  const { data } = await response;
  return fromNutsJson(data, options);
}

/**
 * Determines whether the payload is an error thrown by Axios
 *
 * @param {*} payload The value to test
 *
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 *
 * @see https://github.com/axios/axios/blob/v1.4.0/dist/axios.js#L2830-L2839
 */
export const isAxiosError = (payload: any): payload is AxiosError => payload?.isAxiosError === true;

/**
 * Unwraps a NutsJson errors object from an Axios error response, if possible.
 */
export function unwrapNutsJsonErrors(error: unknown): NutsJsonMessage[] | undefined {
  if (!isAxiosError(error)) return undefined;

  const errors = error.response?.data?.errors;
  return Array.isArray(errors) ? errors : undefined;
}

export default api;
export { tokenProvider, webstore };
